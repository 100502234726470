import axios from "axios";
import axiosConfig from "src/config/axios";

import { isCancelError } from 'src/utils/api/canceller';


type TPackIds = 'cs';

// defaults: 

interface ApiResponseFail {
  status: "RUNTIME_ERROR" | "GENERIC_ERROR" | "NETWORK_ERROR" | "CANCELED",
  messageTranslateKey: string
}

// fetch translate pack:

interface ApiResponse_fetchLanguagePack_OK {
  status: "OK",
  data: {
    pack: {
      name: TPackIds,
      entries: {
        [key: string]: string
      }
    }
  }
}

type ApiResponse_fetchLanguagePack = ApiResponse_fetchLanguagePack_OK | ApiResponseFail;

export async function fetchLanguagePack({
  signal,
  packId,
}: {
  signal?: AbortSignal
  packId: TPackIds
}): Promise<ApiResponse_fetchLanguagePack> {
  try {
    const requestResponse = await axios.request({
      ...axiosConfig,
      url: "/v1/translate/",
      method: "GET",
      signal,
    });
    switch (requestResponse.data.result.status) {
      case 'OK':
        return {
          status: "OK",
          data: {
            pack: requestResponse.data.data.pack
          }
        };
      default:

        return {
          status: 'GENERIC_ERROR',
          messageTranslateKey: 'common.genericError',
        }

    }
  } catch (error: any) {
    if (isCancelError(error)) {
      console.log('Request cancelled');
      return {
        status: 'CANCELED',
        messageTranslateKey: 'requestCanceled'
      }
    }
    console.error(error);
    return {
      status: 'RUNTIME_ERROR',
      messageTranslateKey: 'common.genericError',
    }
  }
}