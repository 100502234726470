import React from "react";
import Splashloading from "src/pome-components/loading/Splash";
import { useTranslate } from "src/store/translate/hooks";

function Readdier({
  children,
}: {
  children: React.ReactNode,
}) {
  const {
    t,
    downloadLanguage,
    errors,
    status
  } = useTranslate();
  React.useEffect(() => {
    downloadLanguage('cs');
  }, [])
  if (status.fetchPack !== 'completed')
    return <Splashloading
      description='Loading data...'
    />

  return <span>
    {children}
  </span>
}

export default Readdier;