import React from "react";

import styles from "./Footer.module.css";

interface Props {
  children: React.ReactNode
}

function Footer({
  children
}: Props) {
  return (
    <div className={styles['footer']}>
      {children}
    </div>
  );
}

export default Footer;