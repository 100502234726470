import React from 'react';
import Basic from 'src/jahoda-atoms/button/Basic';
import InfoView from 'src/jahoda-atoms/info-view/InfoView';
import Body from 'src/jahoda-atoms/modal/Body';
import Footer from 'src/jahoda-atoms/modal/Footer';
import Root from 'src/jahoda-atoms/modal/Root';
import { setMembership, updateDevice } from 'src/store/modifyDevice/actions';
import { useModifiedDevice, useModifyStatus } from 'src/store/modifyDevice/selectors';
import { useCancellation } from 'src/utils/api/canceller';

interface ComponentProps {
  modal: {
    deviceId: string
  }
}
function RevokeCalling({
  modal
}: ComponentProps) {
  const { getNewSignal, cancelSignal } = useCancellation();
  const device = useModifiedDevice();
  const status = useModifyStatus()
  if (!device) return <></>;
  const formattedNumber = device.phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setMembership(null);
    updateDevice({
      signal: getNewSignal()
    });
  }
  return (
    <form onSubmit={formSubmit}>
      <Root>
        <Body>
          <InfoView
            label='Odebrat člentsví'
            description={`Odebrat člentsví u zařízení ${device.title}?`}
            theme='danger'
          />
        </Body>
        <Footer>
          <Basic
            label="Odebrat"
            name="revoke"
            theme='danger'
            type='submit'
            isDisabled={status.state === 'UPDATING'}
          />
          <Basic
            label="Zrušit"
            name="edit1"
            theme='border-gray'
            onPress={() => window.history.back()}
          />
        </Footer>
      </Root>
    </form>
  );
}


export default RevokeCalling;
