import axios from "axios";
import axiosConfig from "src/config/axios";
import kvehyAvatar from 'src/images/kvehy.png';
import montyAvatar from 'src/images/monty.png';
import tessAvatar from 'src/images/tess.png';

import {
  Device,
} from 'src/types';
import { isCancelError } from 'src/utils/api/canceller';

const TEMP_getDeviceImage = (id: string) => {
  switch (id) {
    case '1':
      return kvehyAvatar;
    case '2':
      return tessAvatar;
    case '5':
      return montyAvatar;
    default:
      return null
  }
}


interface ApiResponseFail {
  status: "RUNTIME_ERROR" | "GENERIC_ERROR" | "NETWORK_ERROR" | "CANCELED",
  messageTranslateKey: string
}

// fetchDevices:

interface ApiResponse_fetchDevices_OK {
  status: "OK"
  devices: Device[]
}

type ApiResponse_fetchDevices = ApiResponse_fetchDevices_OK | ApiResponseFail;

export async function fetchDevices({
  signal
}: {
  signal?: AbortSignal
}): Promise<ApiResponse_fetchDevices> {
  try {
    const response = await axios.request({
      ...axiosConfig,
      url: "/v1/devices/",
      method: "GET",
      signal,
    });
    switch (response.data.result.status) {
      case 'OK':
        let devices: Device[] = response.data.data.devices
          .map((d: any) => {
            let id = d.id as string;
            return {
              id: d.id,
              title: d.title,
              membershipDateExpire: d.subscriptionActive ? d.subscriptionActive.split(' ')[0] : null,
              phoneNumber: d.phone,
              imageUrl: TEMP_getDeviceImage(id)
            }
          })

        return {
          status: "OK",
          devices
        };
      default:

        return {
          status: 'GENERIC_ERROR',
          messageTranslateKey: 'genericError',
        }

    }
  } catch (error: any) {
    if (isCancelError(error)) {
      console.log('Request cancelled');
      return {
        status: 'CANCELED',
        messageTranslateKey: 'requestCanceled'
      }
    }
    console.error(error);
    return {
      status: 'RUNTIME_ERROR',
      messageTranslateKey: 'genericError',
    }
  }
}
// update device subscription:

interface ApiResponse_updateDeviceSubscription_OK {
  status: "OK"
}

type ApiResponse_updateDeviceSubscription = ApiResponse_updateDeviceSubscription_OK | ApiResponseFail;

export async function updateDeviceSubscription({
  signal,
  deviceId,
  subscriptionActive
}: {
  signal?: AbortSignal
  deviceId: string
  subscriptionActive: string | null
}): Promise<ApiResponse_updateDeviceSubscription> {
  try {
    const response = await axios.request({
      ...axiosConfig,
      url: "/v1/devices/" + deviceId + "/",
      method: "PUT",
      signal,
      data: {
        subscriptionActive: subscriptionActive === null ? 'f' : subscriptionActive
      }
    });
    switch (response.data.result.status) {
      case 'OK':

        return {
          status: "OK"
        };
      default:

        return {
          status: 'GENERIC_ERROR',
          messageTranslateKey: 'genericError',
        }

    }
  } catch (error: any) {
    if (isCancelError(error)) {
      console.log('Request cancelled');
      return {
        status: 'CANCELED',
        messageTranslateKey: 'requestCanceled'
      }
    }
    console.error(error);
    return {
      status: 'RUNTIME_ERROR',
      messageTranslateKey: 'genericError',
    }
  }
}