import React from "react";

import styles from "./AppHeader.module.css";

interface Props {
  children: React.ReactNode
}

export function Root({
  children
}: Props) {
  return (
    <header className={styles['header']}>
      <div className={styles['container-fluid']}>
        {children}
      </div>
    </header>
  );
}
export function Content({
  children
}: Props) {
  return (
    <div className={styles['header-content']}>
      {children}
    </div>
  );
}
export function Logo({
  src,
  href
}: {
  src: string
  href: string
}) {
  return (
    <a className={styles['logo']} href={href}>
      <img src={src} />
    </a>
  );
}