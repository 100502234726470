import React from 'react';
import { useParams } from 'react-router';
import BasicAlert from 'src/pome-components/alert/Basic';
import FlashContainer from 'src/pome-components/alert/FlashContainer';
import { AvatarDynamic } from 'src/pome-components/avatar/Dynamic';
import Button from 'src/pome-components/button/Button';
import ButtonTab from 'src/pome-components/form/ButtonTab';
import InputFile from 'src/pome-components/input/File';
import FormGroup from 'src/pome-components/input/FormGroup';
import Input from 'src/pome-components/input/Input';
import ContainerFluid from 'src/pome-components/page/ContainerFluid';
import ContentWrapper from 'src/pome-components/page/ContentWrapper';
import SectionRowForm from 'src/pome-components/page/SectionRowForm';
import { useDeviceEditForm } from 'src/store/devices/hooks';
import { useTranslate } from 'src/store/translate/hooks';

function DevicDetail() {
  let {
    deviceId
  } = useParams();
  const { t } = useTranslate();

  let form = useDeviceEditForm(deviceId);
  React.useEffect(() => {
    form.fillData()
  }, [])

  let isFormWithoutErrors = form.isFormWithoutErrors()

  const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.save()
  }, [form])

  return <form onSubmit={handleSubmit}>
    <ContentWrapper>
      <ContainerFluid>
        {form.errors.fill ? <FlashContainer>
          <BasicAlert
            body={<p>{form.errors.fill}</p>}
            theme='warning'
            icon='warning'
          />
        </FlashContainer> : <>
          {form.status.save === 'saved' && <FlashContainer>
            <BasicAlert
              body={<p>{t('form.saveSuccessfull')}</p>}
              theme='info'
              icon='check-circle'
              showClose
            />
          </FlashContainer>}
          <SectionRowForm
            label={t('common.avatar')}
            description={t('form.avatarDescription')}
          >
            <AvatarDynamic
              label={form.fields.title}
              size='lg'
              imageUrl={form.fields.imageUrl || undefined}
            />
            <FormGroup
              label={t('form.uploadNewAvatar')}
              description={t('form.uploadNewAvatarHint')}
              name='avatar'
            >
              <InputFile
                name='avatar'
                isDisabled
              />
            </FormGroup>
          </SectionRowForm>
          <SectionRowForm
            label={t('form.mainSettings')}
            description={t('form.mainSettingsHint')}
          >
            <FormGroup
              label={t('form.fullName')}
              description={t('form.fullNameHint')}
              name='title'
              error={form.errors.title}
            >
              <Input
                name='title'
                value={form.fields.title}
                onChange={(e: any) => {
                  form.setTitle(e.value)
                }}
                isDisabled
              />
            </FormGroup>
            <FormGroup
              label={t('form.phoneNumber')}
              description={t('form.phoneNumberHint')}
              name='phoneNumber'
              error={form.errors.phoneNumber}
            >
              <Input
                name='phoneNumber'
                value={form.fields.phoneNumber}
                onChange={(e: any) => {
                  form.setPhoneNumber(e.value)
                }}
                isDisabled
              />
            </FormGroup>
          </SectionRowForm>
          <SectionRowForm
            label={t('common.subscription')}
            description={t('form.subscriptionHint')}
          >
            <FormGroup
              label={t('form.freeCallingUntil')}
              name='subscriptionEndDate'
              error={form.errors.membershipDateExpire}
            >
              <Input
                name='membershipDateExpire'
                value={form.fields.membershipDateExpire || undefined}
                type='date'
                onChange={(e: any) => {
                  form.setSubscriptionEndDate(e.value)
                }}
              />
            </FormGroup>
          </SectionRowForm>
          <ButtonTab>
            <Button
              label={t('form.updateSettings')}
              name='update'
              type="submit"
              isDisabled={!isFormWithoutErrors || form.status.save === 'saving'}
            />
            <Button
              label={t('common.cancel')}
              name='cancel'
              theme='default'
              onPress={() => window.history.back()}
            />
          </ButtonTab>
        </>}
      </ContainerFluid>
    </ContentWrapper>
  </form>
}

export default DevicDetail;
