import React from "react";
import styles from "./SectionRowForm.module.css";

function SectionRowForm({
  label = '',
  children,
  description,
}: {
  label: string
  children: React.ReactNode,
  description?: string
}) {
  return (
    <div className={[
      "row",
      styles.root
    ].join(' ')}>
      <div className="col-lg-4">
        <h4>{label}</h4>
        <p>
          {description}
        </p>
      </div>
      <div className="col-lg-8">
        {children}
      </div>
      <div className="col-lg-12">
        <hr />
      </div>
    </div>
  );
}

export default SectionRowForm;