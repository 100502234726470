import { Device } from 'src/types';
// import * as apiDevices from 'src/utils/api/devices';
import {
  useDevicesStore,
} from './store';

// export const _addDevice = (Device: Device) => {
//   useDevicesStore.setState((state) => ({ devicesById: { ...state.devicesById, [Device.id]: Device } }));
// };
// export const _updateDevice = (device: Device) => {
//   useDevicesStore.setState((state) => {
//     let nowDeviceState = state.devicesById[device.id];
//     nowDeviceState = {
//       ...nowDeviceState,
//       title: device.title !== null ? device.title : nowDeviceState.title,
//     };
//     return {
//       devicesById: {
//         ...state.devicesById, [device.id]: nowDeviceState
//       }
//     }
//   });
// };

export const _setDevices = (devices: Device[]) => {
  const currentDevices = useDevicesStore.getState().devicesById
  const newDevices = devices.reduce((acc, device) => ({
    ...acc,
    [device.id]: JSON.parse(JSON.stringify(device))
  })
    , currentDevices
  );
  useDevicesStore.setState({ devicesById: newDevices });
};

// export const _removeDevice = (deviceId: string) => {
//   useDevicesStore.setState((state) => {
//     const { [deviceId]: _, ...newDevices } = state.devicesById;
//     return { devicesById: newDevices };
//   });
// };

// export const fetchAllDevices = async (params: {
//   signal?: AbortSignal
// }): Promise<void> => {
//   try {
//     useDevicesStore.setState({
//       state: 'FETCHING',
//       error: ''
//     });
//     const response = await apiDevices.fetchDevices({
//       signal: params.signal
//     });
//     switch (response.status) {
//       case 'OK':
//         _setDevices(response.devices)
//         break;
//     }
//     useDevicesStore.setState({
//       state: 'FETCHED'
//     });
//   } catch (error: any) {
//     useDevicesStore.setState({
//       state: 'ERROR',
//       error: error.message
//     });
//   }
// };

// // export const updateFreeCalling = async (params: {
// //   signal?: AbortSignal,
// //   deviceId: string
// //   subscriptionActive: string | false
// // }): Promise<void> => {
// //   try {
// //     useDevicesStore.setState({
// //       updateDeviceState: 'UPDATING',
// //       updateDeviceError: ''
// //     });
// //     let state = useDevicesStore.getState();
// //     let device = state.devicesById[params.deviceId];
// //     let membershipDateExpire_new = params.subscriptionActive


// //     let response = await apiDevices.updateDeviceSubscription({
// //       deviceId: device.id,
// //       subscriptionActive: membershipDateExpire_new
// //     })
// //     switch (response.status) {
// //       case 'OK':
// //         device.membershipDateExpire = membershipDateExpire_new || null
// //         useDevicesStore.setState({
// //           devicesById: {
// //             ...state.devicesById,
// //             [device.id]: device
// //           }
// //         });
// //         useDevicesStore.setState({
// //           updateDeviceState: 'UPDATED',
// //           updateDeviceError: ''
// //         });
// //         break;
// //     }

// //   } catch (error: any) {
// //     useDevicesStore.setState({
// //       updateDeviceState: 'ERROR',
// //       updateDeviceError: error.message
// //     });
// //   }
// // };

// // export const resetUpdateBehaviour = () => {
// //   useDevicesStore.setState({
// //     updateDeviceState: 'IDLE',
// //     updateDeviceError: '',
// //   });
// // };

export const a = 1;