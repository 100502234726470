import React from "react";
import styles from "./FormGroup.module.css";

function FormGroup({
  name,
  label = '',
  children,
  description,
  error,
}: {
  name: string
  label: string
  children: React.ReactNode,
  error?: string
  description?: string
}) {
  return (
    <div className={styles["form-group"]}>
      <label htmlFor={name}>{label}</label>
      {children}
      {error && <p className={styles["field-error"]}>{error}</p>}
      {description && <small className={styles["form-text"]}>
        {description}
      </small>}
    </div>
  );
}

export default FormGroup;