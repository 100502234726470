import React from "react";
import styles from "./Image.module.css";

export function AvatarImage({
  imageUrl,
  theme = 'default',
  size = 'md'
}: {
  imageUrl: string
  theme?: 'default'
  size?: 'md' | 'lg'
}) {
  return (
    <div className={[
      styles['avatar-image'],
      styles['theme-' + theme],
      styles['size-' + size]
    ].join(' ')}>
      <img src={imageUrl} />
    </div>
  );
}