import React from "react";

import { useModal } from "src/utils/usemodal";
import RevokeCalling from "../devices/modals/RevokeCalling";
import SetCalling from "../devices/modals/SetCalling";

interface Types {
  [key: string]: any;
}

const modalTypes: Types = {
  'SET_CALLING': SetCalling,
  'REVOKE_CALLING': RevokeCalling
}

export interface IModalState_SET_CALLING {
  name: 'SET_CALLING',
  state: {
    deviceId: string
  }
}
export interface IModalState_REVOKE_CALLING {
  name: 'REVOKE_CALLING',
  state: {
    deviceId: string
  }
}
export type IModalData = IModalState_SET_CALLING | IModalState_REVOKE_CALLING

const ModalManager = () => {
  const { currentModal } = useModal();
  let activeModal = currentModal && modalTypes[currentModal.name]
  if (activeModal) {
    return React.createElement(activeModal, {
      modal: currentModal.state
    });
  }
  return <span />
}
export default ModalManager