import React from "react";

import styles from "./InfoView.module.css";

interface Props {
  label: string
  description: string
  theme?: 'primary' | 'danger'
}

function InfoView({
  label,
  description,
  theme = 'primary'
}: Props) {
  return (
    <div className={[styles['view'], styles['theme-' + theme]].join(' ')}>
      <div className={styles['icon']}>

      </div>
      <div className={styles['second']}>
        <h3 className={styles['h3']}>{label}</h3>
        <p className={styles['p']}>{description}</p>
      </div>
    </div>
  );
}

export default InfoView;