import React from 'react';
import Link from 'src/jahoda-atoms/anchors/Link';
import Basic from 'src/jahoda-atoms/button/Basic';
import Fieldset from 'src/jahoda-atoms/form/Fieldset';
import InfoView from 'src/jahoda-atoms/info-view/InfoView';
import Textfield from 'src/jahoda-atoms/input/Textfield';
import Body from 'src/jahoda-atoms/modal/Body';
import Footer from 'src/jahoda-atoms/modal/Footer';
import Root from 'src/jahoda-atoms/modal/Root';
import Paragraph from 'src/jahoda-atoms/text/Paragraph';
import { resetUpdateBehaviour, setMembershipAtDays, updateDevice } from 'src/store/modifyDevice/actions';
import { useModifiedDevice, useModifyStatus } from 'src/store/modifyDevice/selectors';
import { useCancellation } from 'src/utils/api/canceller';
import { formatToDateTime } from 'src/utils/api/date';
import RevokeCalling from './RevokeCalling';

interface ComponentProps {
  modal: {
    deviceId: string;
  }
}
function SetCalling({
  modal
}: ComponentProps) {
  const { getNewSignal, cancelSignal } = useCancellation();
  const [active, setActive] = React.useState<'SET' | 'REVOKE'>('SET');
  const device = useModifiedDevice();
  const status = useModifyStatus()
  const switchToRevoke = () => {
    setActive('REVOKE');
  }
  React.useEffect(() => {
    if (status.state === 'UPDATED') {
      resetUpdateBehaviour()
      window.history.back();
    }
  }, [status.state])
  const [formValues, setFormValues] = React.useState({
    days: 30,
  })
  const formSubmit = React.useCallback((e: React.FormEvent) => {
    e.preventDefault()
    setMembershipAtDays(formValues.days);
    updateDevice({
      signal: getNewSignal()
    });
  }, []);
  if (active === 'REVOKE')
    return <RevokeCalling modal={{
      deviceId: modal.deviceId,
    }} />
  const setFormValue = ({
    name,
    value
  }: {
    name: any,
    value: string
  }) => {
    let partial: Partial<typeof formValues> = {}
    switch (name as keyof typeof formValues) {
      case 'days':
        partial.days = parseInt(value) || 0;
        setMembershipAtDays(partial.days);
        break;
    }
    setFormValues((state: typeof formValues) => {
      return {
        ...state,
        ...partial
      }
    })
  }
  if (!device) return <span />
  const formattedNumber = device.phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
  return (
    <form onSubmit={formSubmit}>
      <Root>
        <Body>
          <Fieldset>
            <InfoView
              label='Nastavit volání zdarma'
              description={`Nastavit volání zdarma pro ${device.title}?`}
            />

            <Textfield
              label='Délka členství (dní)'
              name='days'
              type='number'
              value={formValues.days.toString()}
              onChange={setFormValue}
            />
          </Fieldset>
          <div style={{ marginTop: '0.25rem' }}>
            <Paragraph>Členství bude platné do: {formatToDateTime(device.membershipDateExpire || '')}</Paragraph>
          </div>
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Link
              label='Odebrat členství'
              onPress={switchToRevoke}
            />
          </div>
          {status.errorMessageTranslateKey ? 'Chyba: ' + status.errorMessageTranslateKey : ''}
        </Body>
        <Footer>
          <Basic
            label="Potvrdit"
            name="edit"
            theme='primary'
            isDisabled={status.state === 'UPDATING'}
            type='submit'
          />
          <Basic
            label="Zrušit"
            name="edit1"
            theme='border-gray'
            onPress={() => window.history.back()}
          />
        </Footer>
      </Root>
    </form>
  );
}


export default SetCalling;
