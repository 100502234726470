import React from "react";
import { AvatarImage } from "./Image";
import { AvatarLabelled } from "./Labelled";

export function AvatarDynamic({
  imageUrl,
  label,
  theme = 'default',
  size = 'md'
}: {
  label?: string
  imageUrl?: string
  theme?: 'default'
  size?: 'md' | 'lg'
}) {
  if (imageUrl === undefined) {
    return React.createElement(AvatarLabelled, {
      label: label || '',
      theme,
      size
    })
  }
  return React.createElement(AvatarImage, {
    imageUrl: imageUrl || '',
    theme,
    size
  })
}