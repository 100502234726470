import { create } from 'zustand';

interface TranslateStore {
  entries: {
    [key: string]: string;
  },
  status: {
    fetchPack: 'idle' | 'loading' | 'completed' | 'error'
  }
  errors: {
    fetchPack: string
  }
}

export const useTranslateStore = create<TranslateStore>()(
  () => ({
    entries: {},
    status: {
      fetchPack: 'idle'
    },
    errors: {
      fetchPack: ''
    }
  }),
)
