import React from "react";

import styles from "./Fieldset.module.css";
interface Props {
  children: React.ReactNode
}

function Fieldset({
  children
}: Props) {
  return (
    <fieldset className={styles['fieldset']}>
      {children}
    </fieldset>
  );
}
export default Fieldset; 
