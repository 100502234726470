import React from "react";
import {
  Route,
  Routes,
} from "react-router-dom";
import DeviceDetail from "../devices/Detail";
import HomeMain from "../home/Main";



const Manager = () => {
  return <Routes>
    <Route path="/" element={<HomeMain />} />
    <Route path="/device/:deviceId/" element={<DeviceDetail />} />
  </Routes>
}
// <Route path="*" element={<NotFound />} />

export default Manager