import React from 'react';
import { fetchLanguagePack } from 'src/utils/api/translate';
import { useTranslateStore } from './store';

export function useTranslate() {
  const tStore = useTranslateStore();

  const t = React.useCallback((entry: string) => {
    let found = tStore.entries[entry]
    if (found === undefined) return '%%' + entry + '%%';
    return found;
  }, [tStore])
  const tPlural = React.useCallback((entry: string, count: number) => {
    if (count === 1) return count + ' ' + t(entry + '1')
    if (count >= 2 && count <= 4) return count + ' ' + t(entry + '2')
    return count + ' ' + t(entry + '0')
  }, [tStore])

  const downloadLanguage = React.useCallback(async (code: 'cs') => {
    useTranslateStore.setState(state => ({
      status: {
        ...state.status,
        fetchPack: 'loading'
      }
    }))
    const response = await fetchLanguagePack({
      packId: code
    })
    switch (response.status) {
      case 'OK':
        useTranslateStore.setState(state => ({
          entries: response.data.pack.entries,
          status: {
            ...state.status,
            fetchPack: 'completed',
          },
          errors: {
            ...state.errors,
            fetchPack: '',
          }
        }))
        break;
    }
  }, [useTranslateStore])

  return {
    t,
    tPlural,
    downloadLanguage,
    status: tStore.status,
    errors: tStore.errors
  }
}