import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { IModalData } from 'src/views/page-manager/ModalManager';

export const useModal = (initialMode = false) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setModalState] = React.useState(initialMode)

  const openModal = (modal: IModalData) => {
    navigate(location.pathname, { state: { ...location.state, modal } });
    setModalState(true);
  }
  const getCurrentModal = () => {
    return {
      name: location.state?.modal?.name,
      state: location.state?.modal?.state,
    };
  }

  let currentModal = getCurrentModal()

  return { isModalOpen, openModal, currentModal }
}