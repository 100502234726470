import {
  useModifyDeviceStore
} from './store';

export const useModifiedDevice = () => {
  const { device } = useModifyDeviceStore();
  return device;
};
export const useModifyStatus = () => {
  const { errorMessageTranslateKey, state } = useModifyDeviceStore()
  return { errorMessageTranslateKey, state };
};