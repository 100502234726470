import React from "react";
import { useNavigate } from "react-router";
import { useTranslate } from "src/store/translate/hooks";
import { ButtonClickEvent } from "src/types";
import { formatDateToNormalize, isDateInPast } from "src/utils/api/date";
import { AvatarDynamic } from "../avatar/Dynamic";
import Button from "../button/Button";
import IconView from "../icon/View";
import styles from "./DeviceRecord.module.css";

export function Root({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.content}>
      {children}
    </div>
  );
}
export function EventItem({
  title,
  id,
  imageUrl,
  membershipDateExpire,
  showActions = false
}: {
  title: string
  id: string
  imageUrl: string | null,
  membershipDateExpire: string | null
  showActions?: boolean
}) {
  const { tPlural, t } = useTranslate()
  let isActiveMembership = membershipDateExpire ? !isDateInPast(membershipDateExpire) : false;
  let membershipinfo = '';
  let printNormalized = ''
  if (membershipDateExpire) {
    let calculatedDateDiff = formatDateToNormalize(membershipDateExpire)
    isActiveMembership = calculatedDateDiff.direction === 'future';
    switch (calculatedDateDiff.special) {
      case 'today':
        printNormalized = t('date.ending') + ' ' + t('date.today');
        break;
      case 'tomorrow':
        printNormalized = t('date.ending') + ' ' + t('date.tomorrow');
        break;
      case 'yesterday':
        printNormalized = t('date.ended') + ' ' + t('date.yesterday');
        break;
      default:
        printNormalized = (isActiveMembership ? (t('date.endingIn')) : t('date.endedBefore')) + ' ' + calculatedDateDiff.calculated.map(now => {
          return tPlural('date.plural.' + now.unit, now.value)
        })
          .join(' ' + t('common.and') + ' ')
        break;
    }
  } else {
    printNormalized = t('common.expired');
  }
  let navigate = useNavigate()
  const handlePress = React.useCallback((e: ButtonClickEvent) => {
    navigate('/device/' + id + '/')
  }, [])
  return (
    <div className={styles.eventItem}>
      <div className={styles.avatar}>
        <AvatarDynamic
          label={title}
          imageUrl={imageUrl || undefined}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.body}>
          <span>{title}</span>
        </div>
        <div className={styles.info}>
          <span className={[styles.status, styles['state-' + (isActiveMembership ? 'active' : 'inactive')]].join(' ')}>
            <div />
          </span>
          <span>{printNormalized}</span>
        </div>
      </div>
      {showActions && <div className={styles.actions}>
        <Button
          label=''
          theme='default'
          name='action'
          onPress={handlePress}
          hasIcon
        >
          <IconView
            icon="pencil"
          />
        </Button>
      </div>}

    </div>
  );
}