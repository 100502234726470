import React from "react";

import styles from "./Paragraph.module.css";

interface Props {
  children: React.ReactNode
}

function Paragraph({
  children
}: Props) {
  return (
    <div className={styles['text']}>
      {children}
    </div>
  );
}

export default Paragraph;