import React from "react";
import { InputChangeEvent } from "src/types";
import styles from "./Input.module.css";


interface onChangeArgs {
  value: string
  name: string
}

interface Props {
  name: string
  value?: string
  isDisabled?: boolean
  onChange?: (event: InputChangeEvent) => void
  type?: 'text' | 'number' | 'date' | 'file'
}


function Input({
  name,
  value = '',
  isDisabled,
  onChange,
  type = 'text'
}: Props) {
  let handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({
      name,
      value: e.target.value,
      e
    });
  }, [name])
  return (
    <input
      name={name}
      value={value}
      onChange={handleChange}
      type={type}
      disabled={isDisabled}
      className={styles.input}
    />
  );
}

export default Input;