import { AxiosRequestConfig } from "axios";

const axiosConfig: AxiosRequestConfig<any> = {
  baseURL: 'https://xv-mrs-ccts.xive.cz/api',
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  validateStatus: function (status) {
    return status >= 200 && status < 403; // default
  },
  withCredentials: true,
}

export default axiosConfig