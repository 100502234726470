import React from 'react';
import { Device } from 'src/types';
import { useCancellation } from 'src/utils/api/canceller';
import * as apiDevices from 'src/utils/api/devices';
import { _setDevices } from './actions';
import {
  useDevicesStore,
} from './store';

type TStatus = 'IDLE' | 'FETCHING' | 'FETCHED' | 'ERROR'

export const useAllDevices = () => {

  let [status, setStatus] = React.useState<TStatus>('IDLE');
  let [error, setError] = React.useState<string>('');
  const { getNewSignal, cancelSignal } = useCancellation();
  const { devicesById } = useDevicesStore();
  const getDevices = (): Device[] => {
    return Object.values(devicesById)
      .map((device) => ({
        ...device,
      }))
      .sort((a, b) => {
        let a_membershipDateExpire = a.membershipDateExpire || '0';
        let b_membershipDateExpire = b.membershipDateExpire || '0';
        return b.title.localeCompare(b.title) * -1 || a_membershipDateExpire.localeCompare(b_membershipDateExpire) * -1;
      });
  };

  const fetch = React.useCallback(async (params: {
  } = {}): Promise<void> => {
    try {
      let signal = getNewSignal()
      setStatus('FETCHING');
      const response = await apiDevices.fetchDevices({
        signal: signal
      });
      switch (response.status) {
        case 'OK':
          _setDevices(response.devices)
          break;
      }
      setStatus('FETCHED');

    } catch (error: any) {
      setStatus('FETCHED');
      setError(error.message);
    }
  }, []);

  return {
    list: getDevices(),
    fetch,
    status,
    error
  }
}

// export const useDeviceById = (deviceId: string | undefined) => {
//   let [error, setError] = React.useState<string>('');
//   const { devicesById } = useDevicesStore();
//   const getDeviceById = (deviceId: string | undefined) => {
//     if (deviceId === undefined || devicesById[deviceId] === undefined) {
//       setError('devices.deviceNotFound');
//       return undefined;
//     }
//     return devicesById[deviceId];
//   };

//   return {
//     found: getDeviceById(deviceId),
//     error
//   }
// }

export const useDeviceById = (deviceId: string | undefined) => {
  const [found, setDevice] = React.useState<Device | undefined>(undefined);
  let [error, setError] = React.useState<string>('');
  const { devicesById } = useDevicesStore();

  React.useEffect(() => {
    let newDevice: Device | undefined = undefined;
    if (deviceId !== undefined && devicesById[deviceId] !== undefined) {
      newDevice = devicesById[deviceId];
    }
    setDevice(newDevice);
    if (newDevice === undefined) {
      setError('devices.deviceNotFound');
    }
  }, [deviceId]);

  return {
    found,
    error
  };
}

interface IEditFormErrors {
  title: string
  phoneNumber: string
  membershipDateExpire: string
  imageUrl: string
  save: string
  fill: string
}

const titleRegex = /^.{2,16}$/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// const passwordRegex = /^(?=.*[!@#$%^&*])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const phoneNumberRegex = /^\d{9}$/;
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;


export const useDeviceEditForm = (deviceId: string | undefined) => {
  const { devicesById } = useDevicesStore();
  const [fields, setFields] = React.useState<Device>({
    id: '',
    title: '',
    phoneNumber: '',
    membershipDateExpire: null,
    imageUrl: null,
  });
  let [errors, setErrors] = React.useState<IEditFormErrors>({
    title: '',
    phoneNumber: '',
    membershipDateExpire: '',
    imageUrl: '',
    save: '',
    fill: '',
  });
  let [status, setStatus] = React.useState<{
    fill: 'idle' | 'fetching' | 'fetched' | 'error',
    save: 'idle' | 'saving' | 'saved' | 'error',
  }>({
    fill: 'idle',
    save: 'idle',
  });

  const fillData = React.useCallback(() => {
    if (deviceId !== undefined && devicesById[deviceId] !== undefined) {
      let clonedDevice: Device = JSON.parse(JSON.stringify(devicesById[deviceId]));
      setFields(clonedDevice)
      setStatus(state => ({
        ...state,
        fill: 'fetched',
      }))
    } else {
      setStatus(state => ({
        ...state,
        fill: 'error',
      }))
      setErrors(state => ({
        ...state,
        fill: 'device.notFound',
      }))
    }
  }, [devicesById])

  const setTitle = React.useCallback((title: string) => {
    let error = '';
    setFields(state => ({
      ...state,
      title
    }))
    if (!titleRegex.test(title)) {
      error = 'device.form.invalidTitle';
    }
    setErrors(state => ({
      ...state,
      title: error
    }))
  }, [])

  const setPhoneNumber = React.useCallback((phoneNumber: string) => {
    let error = '';
    setFields(state => ({
      ...state,
      phoneNumber
    }))
    if (!phoneNumberRegex.test(phoneNumber)) {
      error = 'device.form.invalidPhoneNumber';
    }
    setErrors(state => ({
      ...state,
      phoneNumber: error
    }))
  }, [])

  const setSubscriptionEndDate = React.useCallback((endDate: string) => {
    let error = '';
    setFields(state => ({
      ...state,
      membershipDateExpire: endDate
    }))
    if (!dateRegex.test(endDate)) {
      error = 'device.form.invalidMembershipDateExpire';
    }
    setErrors(state => ({
      ...state,
      membershipDateExpire: error
    }))
  }, [])

  const isFormWithoutErrors = React.useCallback(() => {
    const { phoneNumber, title, membershipDateExpire } = errors;

    if (
      phoneNumber !== ''
      || title !== ''
      || membershipDateExpire !== ''
    ) {

      return false;
    }

    return true;
  }, [errors])

  const save = React.useCallback(async () => {
    if (isFormWithoutErrors() === false || deviceId === undefined) {
      return false;
    }
    setStatus(state => ({
      ...state,
      save: 'saving',
    }))
    let response = await apiDevices.updateDeviceSubscription({
      deviceId: deviceId,
      subscriptionActive: fields.membershipDateExpire
    })
    switch (response.status) {
      case 'OK':
        setStatus(state => ({
          ...state,
          save: 'saved',
        }))
        break;
      case 'GENERIC_ERROR':
        setStatus(state => ({
          ...state,
          save: 'error',
        }))
        break;
    }

    _setDevices([fields])
  }, [fields, deviceId, status])

  return {
    fields,
    errors,
    status,
    fillData,
    setPhoneNumber,
    setTitle,
    setSubscriptionEndDate,
    isFormWithoutErrors,
    save,
  };
}

