import React from "react";

import styles from "./ContainerFluid.module.css";

interface Props {
  children: React.ReactNode
}

function ContainerFluid({
  children
}: Props) {
  return (
    <div className={styles['container']}>
      {children}
    </div>
  );
}

export default ContainerFluid;