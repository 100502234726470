import { Device } from 'src/types';
import * as apiDevices from 'src/utils/api/devices';
import { makeDeepCopy } from 'src/utils/api/runtime';
import { useDevicesStore } from '../devices/store';
import { useModifyDeviceStore } from './store';


export const setModifyDevice = (device: Device) => {
  try {
    useModifyDeviceStore.setState({
      device: makeDeepCopy(device)
    });
  } catch (error: any) {
    useModifyDeviceStore.setState({
      state: 'ERROR',
      errorMessageTranslateKey: error.errorMessageTranslateKey || 'generic_error'
    });
  }
};

export const updateDevice = async (params: {
  signal?: AbortSignal,
} = {}): Promise<void> => {
  try {

    let { device } = useModifyDeviceStore.getState();
    if (!device) throw new Error('Device not assigned to modify')
    useModifyDeviceStore.setState({
      state: 'UPDATING',
      errorMessageTranslateKey: ''
    });

    let response = await apiDevices.updateDeviceSubscription({
      deviceId: device.id,
      subscriptionActive: device.membershipDateExpire || null,
      signal: params.signal
    })
    switch (response.status) {
      case 'OK':
        if (!device) throw new Error('Device not assigned to modify')
        useDevicesStore.setState((state) => {
          if (!device) return state;
          return {
            devicesById: {
              ...state.devicesById,
              [device.id]: device
            }
          }
        });
        useModifyDeviceStore.setState({
          state: 'UPDATED',
          errorMessageTranslateKey: ''
        });
        break;
    }

  } catch (error: any) {
    useModifyDeviceStore.setState({
      state: 'ERROR',
      errorMessageTranslateKey: error.errorMessageTranslateKey || 'generic_error'
    });
  }
};

export const setMembershipAtDays = (days: number) => {
  var date = new Date();
  date.setDate(date.getDate() + days);
  let membershipDateExpire = date.toISOString().slice(0, 10) + ' 23:59:59';
  setMembership(membershipDateExpire)
};
export const setMembership = (value: string | null) => {
  try {
    const { device } = useModifyDeviceStore.getState();
    if (!device) throw new Error('Device not assigned to modify')
    device.membershipDateExpire = value;
    useModifyDeviceStore.setState({
      device
    });
  } catch (error: any) {
    useModifyDeviceStore.setState({
      state: 'ERROR',
      errorMessageTranslateKey: error.errorMessageTranslateKey || 'generic_error'
    });
  }
};

export const resetUpdateBehaviour = () => {
  useModifyDeviceStore.setState({
    device: undefined,
    state: 'IDLE',
    errorMessageTranslateKey: '',
  });
};