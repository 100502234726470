import React from "react";
import { useButton } from 'react-aria';

import { ButtonClickEvent } from "src/types";
import styles from "./Button.module.css";

interface Props {
  label: string
  children?: React.ReactNode
  name: string
  isDisabled?: boolean
  onPress?: (event: ButtonClickEvent) => void
  size?: 'sm' | 'md',
  theme?: 'primary' | 'primary-text' | 'default' | 'tertiary',
  type?: 'submit' | 'reset' | 'button',
  hasIcon?: boolean
}

function Button({
  label,
  children,
  name,
  isDisabled,
  onPress,
  theme = 'primary',
  type = 'button',
  size = 'md',
  hasIcon = false
}: Props) {
  let handleClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    onPress && onPress({
      label,
      name,
      e
    });
  }, [label, name])
  let ref = React.useRef(null);
  let { buttonProps } = useButton({
    id: name,
    'aria-label': label,
    isDisabled,
    type
  }, ref);
  return (
    <button
      {...buttonProps}
      onClick={handleClick}
      className={[
        styles.button,
        styles.btn,
        styles['theme-' + theme],
        styles['size-' + size],
        hasIcon ? styles['btn-icon'] : '',
      ].join(' ')}
    >{label || children}</button>
  );
}

export default Button;