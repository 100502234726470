import React from "react";
import { useButton } from "react-aria";
import styles from "./Tabs.module.css";

export interface TabOptionValue {
  label: string
  value: string
}

interface Props {
  name: string
  value?: string
  options: TabOptionValue[]
  onChange?: (args: TabOptionValue) => void
}

function Tabs({
  name,
  value = '',
  options,
  onChange,
}: Props) {
  const processClick = React.useCallback((e: TabOptionValue) => {
    onChange && onChange(e)
  }, [])
  return (
    <div className={styles.tabs}>
      {options.map(option => <Tab
        label={option.label}
        key={option.value}
        value={option.value}
        name={name}
        active={value === option.value}
        onPress={processClick}
      />)}
    </div>
  );
}
function Tab({
  label,
  value,
  name,
  active = false,
  onPress
}: {
  value: string
  label: string
  name: string
  active: boolean
  onPress?: (e: TabOptionValue) => void
}) {
  const processPress = React.useCallback((e: any) => {
    onPress && onPress({
      label,
      value
    })
  }, [label, value])
  let ref = React.useRef(null);
  let { buttonProps } = useButton({
    id: name + '-' + value,
    'aria-label': label,
    onPress: processPress,
  }, ref);
  return (
    <div>
      <button
        {...buttonProps}
        className={[styles['nav-link'], active ? (styles['active']) : ''].join(' ')}
      >
        {label}
      </button>
    </div>
  );
}

export default Tabs;