import React from "react";
import { useTextField } from 'react-aria';

import styles from "./Textfield.module.css";

interface onChangeArgs {
  value: string
  name: string
}

interface Props {
  label: string
  name: string
  value?: string
  isIndeterminate?: boolean
  isDisabled?: boolean
  onChange?: (args: onChangeArgs) => void
  type?: 'text' | 'number'
}


function Textfield({
  label,
  name,
  value = '',
  isDisabled,
  onChange,
  type = 'text'
}: Props) {
  let ref = React.useRef(null);
  let { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField({
    id: name,
    name,
    'aria-label': label,
    isDisabled,
    type,
    onChange: (value_new: string) => {
      if (onChange) {
        onChange({ name, value: value_new });
      }
    },
    value
  }, ref);
  return (
    <div className={styles['textfield']}>
      <label htmlFor={name} className={styles.label}>{label}</label>
      <div>
        <input {...inputProps} className={styles.input} />
      </div>
    </div>
  );
}

export default Textfield;