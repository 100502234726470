import React from "react";
import styles from "./Block.module.css";

function LoadingBlock({
  theme = 'light',
  size = 'md'
}: {
  theme?: 'light' | 'dark'
  size?: 'sm' | 'md'
}) {
  return (
    <div className={styles["loading"]}>
      <div className={styles["container"]}>
        <span className={[
          styles["spinner"],
          styles["size-" + size],
          styles["theme-" + theme]
        ].join(' ')} />
      </div>
    </div>
  );
}

export default LoadingBlock;