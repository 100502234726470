import React from 'react';

export const useCancellation = () => {
  const controller = React.useRef<AbortController | null>(null);

  const cancelSignal = React.useCallback(() => {
    if (!controller.current) return;
    controller.current.abort();
    controller.current = null;
  }, []);

  const getNewSignal = React.useCallback(() => {
    if (controller.current)
      controller.current.abort();
    controller.current = new AbortController();
    return controller.current.signal;
  }, []);

  React.useEffect(() => {
    return cancelSignal;
  }, [cancelSignal]);

  return { getNewSignal, cancelSignal };
};

export const isCancelError = (err: any) => err instanceof DOMException && err.name === "AbortError";