import React from "react";
import { useButton } from 'react-aria';

import styles from "./Basic.module.css";

interface Props {
  label: string
  name: string
  isDisabled?: boolean
  onPress?: () => void
  theme?: 'primary' | 'border-gray' | 'danger',
  type?: 'submit' | 'reset' | 'button'
}

function Basic({
  label,
  name,
  isDisabled,
  onPress,
  theme = 'primary',
  type = 'button'
}: Props) {
  let ref = React.useRef(null);
  let { buttonProps } = useButton({
    id: name,
    'aria-label': label,
    isDisabled,
    onPress,
    type
  }, ref);
  return (
    <button
      {...buttonProps}
      className={[styles.button, styles['theme-' + theme]].join(' ')}
    >{label}</button>
  );
}

export default Basic;