import React from "react";
import ButtonBasic from "src/pome-components/button/Button";
import { ButtonClickEvent } from "src/types";
import IconView from "../icon/View";
import styles from "./Basic.module.css";

export function Root({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.root}>
      {children}
    </div>
  );
}

export function Title({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.title}>
      {children}
    </div>
  );
}

export function Content({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.content}>
      {children}
    </div>
  );
}

export function Body({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.body}>
      {children}
    </div>
  );
}

export function Actions({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <div className={styles.actions}>
      {children}
    </div>
  );
}

export default function BasicAlert({
  title,
  icon = 'information-o',
  body,
  actions,
  theme = 'info',
  showClose = false
}: {
  title?: string
  icon?: 'information-o' | 'check-circle' | 'warning'
  body?: React.ReactNode
  actions?: React.ReactNode
  theme?: 'info' | 'warning'
  showClose?: boolean
}) {
  const [closed, setClosed] = React.useState(false);
  if (closed) return <span />
  return (
    <div className={[
      styles.root,
      styles['theme-' + theme]
    ].join(' ')}>
      {title && <Title>{title}</Title>}
      {body && <Body>{body}</Body>}
      {actions && <Actions>{actions}</Actions>}
      <span className={styles.iconMain}>
        <IconView
          icon={icon}
        />
      </span>
      {showClose && <span className={styles.iconClose}>
        <ButtonBasic
          label=""
          name="alert-close"
          hasIcon={true}
          size="sm"
          theme="tertiary"
          onPress={(event: ButtonClickEvent) => {
            setClosed(true);
          }}
        >
          <IconView
            icon='close'
          />
        </ButtonBasic>
      </span>}
    </div>
  );
}