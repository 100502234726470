import React from "react";
import logo from "src/images/logo-cactus-192.png";
import LoadingBlock from "./Block";
import styles from "./Splash.module.css";



function Splashloading({
  description
}: {
  description?: string
}) {
  return (
    <div className={styles["loading"]}>
      <img src={logo} style={{ height: '192px' }} />

      <LoadingBlock theme="light" />

      <p>
        {description}
      </p>
    </div>
  );
}

export default Splashloading;