import { Device } from 'src/types';
import { create } from 'zustand';

interface DeviceStore {
  devicesById: {
    [key: string]: Device;
  }
}

export const useDevicesStore = create<DeviceStore>()(
  () => ({
    devicesById: {},
  }),
)
