import { Device } from 'src/types';
import { create } from 'zustand';


interface ModifyDeviceStore {
  device: Device | undefined,
  state: 'IDLE' | 'UPDATING' | 'UPDATED' | 'ERROR'
  errorMessageTranslateKey: string
}

export const useModifyDeviceStore = create<ModifyDeviceStore>()(
  () => ({
    device: undefined,
    state: 'IDLE',
    errorMessageTranslateKey: '',
  }),
)
