import React from "react";

import styles from "./Root.module.css";

interface Props {
  children: React.ReactNode
}

function Root({
  children
}: Props) {
  return (
    <div className={styles['root']}>
      <div>
        <div className={styles['container']}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Root;