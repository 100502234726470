import React from "react";
import styles from "./ButtonTab.module.css";

function ButtonTab({
  children,
}: {
  children: React.ReactNode,
}) {
  return (
    <div className={styles["buttons-tab"]}>
      {children}
    </div>
  );
}

export default ButtonTab;