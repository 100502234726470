import React from "react";
import * as AppHeader from "src/pome-components/page/AppHeader";
import { useTranslate } from "src/store/translate/hooks";

import logoCactus from "src/images/logo-cactus-192.png";

function AppNavbar({
}: {
  }) {
  const {
    t,
  } = useTranslate();
  return <AppHeader.Root>
    <AppHeader.Content>
      <AppHeader.Logo
        src={logoCactus}
        href='/'
      />
      <p>
        {t('app.title')}
      </p>
    </AppHeader.Content>
    <div style={{ flex: '0 0 auto' }} />
    <div style={{ flex: '0 0 auto' }} />
  </AppHeader.Root>
}

export default AppNavbar;