
type TNormalize = {
  unit: 'days' | 'weeks'
  value: number
}
export function formatDateToNormalize(date_string: string): {
  direction: 'future' | 'past'
  calculated: TNormalize[]
  special?: 'yesterday' | 'tomorrow' | 'today'
} {
  const date = getDateFromString(date_string);
  let diff = date.getTime() - new Date().getTime();

  const MINUTE = 60 * 1000;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;

  let diffDays = Math.floor(diff / DAY);
  let isPast = diffDays <= 0;
  if (isPast) {
    ++diffDays;
  }
  const timeUnits = [
    { value: WEEK, unit: 'weeks' },
    { value: DAY, unit: 'days' },
  ];
  // Check for "yesterday"
  if (diffDays === -1) {
    return {
      calculated: [{
        unit: 'days',
        value: 1
      }],
      direction: 'past',
      special: 'yesterday'
    }
  }

  // Check for "today"
  if (diffDays === 0) {
    return {
      calculated: [{
        unit: 'days',
        value: 0
      }],
      direction: 'future',
      special: 'today'
    }
  }

  // Check for "tomorrow"
  if (diffDays === 1) {
    return {
      calculated: [{
        unit: 'days',
        value: 1
      }],
      direction: 'future',
      special: 'tomorrow'
    }
  }

  diffDays = Math.abs(diffDays);
  const ret: TNormalize[] = [];
  const weeks = Math.floor(diffDays / 7)
  if (weeks > 0) {
    ret.push({
      unit: 'weeks',
      value: weeks
    })
  }
  let remainingDays = weeks > 0 ? Math.floor(diffDays % (weeks * 7)) : diffDays;
  if (remainingDays > 0)
    ret.push({
      unit: 'days',
      value: remainingDays
    })
  return {
    direction: isPast ? 'past' : 'future',
    calculated: ret
  }
}



export function getDateFromString(date_string: string) {
  return new Date(date_string)
}

export function formatOnlyDate(date_string: string) {
  const date = getDateFromString(date_string);

  return `${formatZeros(date.getDate())}.${formatZeros(date.getMonth())}.${date.getFullYear()}`
}
export function formatToDateTime(date_string: string) {
  const date = getDateFromString(date_string);

  return `${formatZeros(date.getDate())}.${formatZeros(date.getMonth())}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
}

export function isDateInPast(date_string: string) {
  const date = getDateFromString(date_string);

  let diff = date.getTime() - new Date().getTime();

  return diff <= 0;
}

function formatZeros(num: number) {
  if (num < 10) {
    return "0" + num;
  } else {
    return num.toString();
  }
}