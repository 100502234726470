import React from 'react';
import AlertBasic from 'src/pome-components/alert/Basic';
import Button from 'src/pome-components/button/Button';
import * as DeviceRecord from 'src/pome-components/list/DeviceRecord';
import LoadingBlock from 'src/pome-components/loading/Block';
import ContainerFluid from 'src/pome-components/page/ContainerFluid';
import ContentWrapper from 'src/pome-components/page/ContentWrapper';
import { PageTitleHolder } from 'src/pome-components/page/TitleHolder';
import Tabs, { TabOptionValue } from 'src/pome-components/tabs/Tabs';
import { useAllDevices } from 'src/store/devices/hooks';
import { useTranslate } from 'src/store/translate/hooks';
import { isDateInPast } from 'src/utils/api/date';

function HomeMain() {
  let [tabState, setTabState] = React.useState<'all' | 'active' | 'expired'>('all');
  let [editState, setEditState] = React.useState<boolean>(false);
  const { t } = useTranslate();
  const updateTabState = React.useCallback((e: TabOptionValue) => {
    setTabState(e.value as any)
  }, [])

  const devices = useAllDevices()
  const fetch = React.useCallback(() => {
    devices.fetch()
  }, [])
  React.useEffect(() => {
    fetch()
  }, [])

  const devicesPrint = React.useMemo(() => {
    const active: React.ReactNode[] = [];
    const expired: React.ReactNode[] = [];

    devices.list.forEach(device => {
      const el = (
        <DeviceRecord.EventItem
          key={device.id}
          id={device.id}
          title={device.title}
          imageUrl={device.imageUrl}
          membershipDateExpire={device.membershipDateExpire}
          showActions={editState}
        />
      );

      const isActiveMembership = device.membershipDateExpire
        ? !isDateInPast(device.membershipDateExpire)
        : false;

      if (isActiveMembership) {
        active.push(el);
      } else {
        expired.push(el);
      }
    });

    return { active, expired };
  }, [devices]);
  return <ContentWrapper>
    <ContainerFluid>
      <PageTitleHolder
        label={t('common.devices')}
        actions={[
          <Button
            label={t('common.refresh')}
            name='refresh'
            theme='primary'
            key='refresh'
            onPress={fetch}
            isDisabled={devices.status === 'FETCHING'}
          />,
          <Button
            label={t('editMode.editRecords')}
            name='edit-records'
            theme='primary-text'
            key='edit-records'
            onPress={() => { setEditState(true) }}
            isDisabled={devices.status === 'FETCHING'}
          />
        ]}
      />
      {editState &&
        <div style={{ margin: '12px 0 24px' }}>
          <AlertBasic
            title={t('editMode.headingInfo')}
            body={<p>{t('editMode.paragraphInfo')}</p>}
            actions={[
              <Button
                label={t('editMode.disableEditing')}
                name='cancel'
                theme='default'
                onPress={() => { setEditState(false) }}
              />
            ]}
          />
        </div>}
      <Tabs
        name='tabs'
        value={tabState}
        options={[
          {
            label: t('common.all'),
            value: 'all',
          }, {
            label: t('common.active'),
            value: 'active',
          }, {
            label: t('common.expired'),
            value: 'expired',
          }]}
        onChange={updateTabState}
      />

      {devices.status === 'FETCHING' && <LoadingBlock />}

      <DeviceRecord.Root>
        {tabState === 'all' ? ([...devicesPrint.active, ...devicesPrint.expired]) : devicesPrint[tabState]}
      </DeviceRecord.Root>
    </ContainerFluid>
  </ContentWrapper>
}

export default HomeMain;
