import React from "react";
import iconsSVG from "src/images/icons.svg";
import styles from "./View.module.css";



export default function IconView({
  icon = 'close',
}: {
  icon: 'close'
  | 'information-o' | 'information'
  | 'check-circle'
  | 'warning'
  | 'pencil'
}) {
  return (
    <svg className={styles.icon}>
      <use href={iconsSVG + "#" + icon}></use>
    </svg>
  );
}