import React from "react";
import ButtonBasic from "src/pome-components/button/Button";
import { InputChangeEvent } from "src/types";
import styles from "./File.module.css";


interface Props {
  name: string
  value?: string
  isDisabled?: boolean
  onChange?: (event: InputChangeEvent) => void
}


function InputFile({
  name,
  value = '',
  isDisabled,
  onChange,
}: Props) {
  let handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({
      name,
      value: e.target.value,
      e
    });
  }, [name])
  return (
    <div className={styles['input-file']}>
      <label htmlFor={name}>
        <ButtonBasic
          label="Choose file..."
          name={name}
          theme="default"
          isDisabled={isDisabled}
          type="button"
        />
        <span>No file chosen.</span>
      </label>
      <input
        name={name}
        id={name}
        value={value}
        onChange={handleChange}
        disabled={isDisabled}
        type='file'
      />

    </div>
  );
}

export default InputFile;