import React from "react";
import styles from "./Labelled.module.css";

function printText(text: string) {
  let result = '';
  const words = text.split(' ');
  if (words.length === 1) {
    result = words[0].substring(0, 2);
  } else {
    result = words[0].charAt(0) + words[1].charAt(0);
  }
  return result;
}

export function AvatarLabelled({
  label,
  theme = 'default',
  size = 'md'
}: {
  label: string
  theme?: 'default'
  size?: 'md' | 'lg'
}) {
  return (
    <div className={[
      styles['avatar-text'],
      styles['theme-' + theme],
      styles['size-' + size]
    ].join(' ')}>
      {printText(label)}
    </div>
  );
}