import React from "react";
import styles from "./TitleHolder.module.css";

export function PageTitleHolder({
  label,
  actions
}: {
  label: string
  actions: React.ReactNode[]
}) {
  return (
    <div className={styles.holder}>
      <h1 className={styles.title}>{label}</h1>
      <div className={styles.actions}>
        {actions}
      </div>
    </div>
  );
}