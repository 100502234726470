import React from "react";

import {
  BrowserRouter as Router,
} from "react-router-dom";

import AppNavbar from "./views/AppNavbar";
import Readdier from "./views/Readdier";
import PageManager from "./views/page-manager/Manager";
import ModalManager from "./views/page-manager/ModalManager";

function App() {
  return (
    <Router>
      <Readdier>
        <AppNavbar />
        <PageManager />
      </Readdier>
      <ModalManager />
    </Router>
  );
}


export default App;
