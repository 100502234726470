import React from "react";

import styles from "./Link.module.css";
interface Props {
  label: string
  onPress: () => void
}

function Link({
  label,
  onPress
}: Props) {
  return (
    <span className={styles['link']} onClick={onPress}>
      {label}
    </span>
  );
}
export default Link; 
